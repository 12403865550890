#courses-filters {
  gap: 20px;
}

.courses-filter {
  position: relative;

  & button {
    outline: none;
  }

  &__button {
    position: relative;

    min-width: 140px;
    height: 50px;
    padding: 5px 8px;
    background-color: transparent;
    border: 1px solid $gray69;
    border-radius: 16px;

    color: $text-color;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    font-family: Work Sans;
    text-align: center;

    transition: color 0.25s ease;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 2px solid transparent;
      border-radius: 16px;

      transition: border 0.25s ease;
    }
  }

  &.active &__button,
  &.with-selected &__button {
    color: $blue-active;
    overflow: hidden;

    &::before {
      border-color: $blue-active;
    }
  }

  &.with-selected &__button {
    border-top-right-radius: 0px;

    &::before {
      border-top-right-radius: 0px;
    }
  }

  &:not(.with-selected) &__remove {
    display: none;
  }
  &__remove {
    position: absolute;
    right: -16px;
    top: -15px;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #303030;
    border: none;
    border-radius: 100%;
  }

  &__wrapper-list {
    position: absolute;
    top: calc(100% + 15px);
    left: 0;

    z-index: 999;

    // position: relative;
    padding: 8px 0;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    background-color: $white;

    &::before {
      content: '';
      position: absolute;
      top: -3.5px;
      left: 63px;

      display: block;
      height: 7px;
      width: 7px;
      background-color: #fff;
      border-left: 1px solid #e9e9e9;
      border-top: 1px solid #e9e9e9;
      transform: rotate(45deg);
    }

    .smart-input {
      width: 192px;
    }
  }

  &:not(.active) &__wrapper-list {
    display: none;
  }

  &__list {
    list-style: none;
    width: max-content;
    min-width: 135px;

    // Do not replace the complete below css as this is for meetup filters
    h2 {
      color: #31364e;
      font-family: Work Sans;
      font-size: 14px;
      line-height: normal;
      margin-bottom: 15px;
    }
    .dropdown {
      position: relative;
      width: 220px;

      .custom-dropdown {
        background-color: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        color: #9598a6;
        padding: 15px;
        position: relative;
        text-align: left;
        width: 100%;
      }

      .custom-dropdown:after {
        content: url(/img/ic-arrow-down.svg);
        height: 24px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
      }
      .dropdown-menu {
        border-radius: 0 0 4px 4px;
        border: 1px solid #2465b3;
        border-top: none;
        box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
        margin: 0;
        width: 100%;

        .dropdown-item {
          color: #303650;
          font-size: 14px;
        }

        li {
          cursor: pointer;
          padding: 12px 20px;
          text-align: center;
        }
      }
    }
    .checkbox-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .checkbox-wrapper {
        align-items: center;
        display: flex;
        margin-bottom: 10px;

        .checkbox-text {
          font-family: Work Sans;
          font-size: 12px;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }
  }

  &__list-item {
    cursor: pointer;
    padding: 12px 20px;
    color: #31364e;
    font-size: 14px;
    text-align: center;

    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: rgba(137, 190, 236, 0.2);
    }
  }
}

.upcoming_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 30px;
}
.upcoming_course .course-card {
  min-height: 321px;
}

.course-card {
  position: relative;
  height: max-content;
  //
  height: 100%;
  max-height: 380px;
  // margin: 15px 0;
  padding: 20px 20px 16px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    min-height: 100px;
    color: #31364e;
    margin-bottom: 20px;

    & span {
      display: block;
    }
  }

  &__type {
    font-family: Work Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;

    margin-bottom: 4px;
  }

  &__name {
    font-family: Lora;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
  }

  &__others {
    color: #4f5573;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }

  &__date {
    width: max-content;
    padding: 2px 8px;
    border-radius: 6px;
    background:
      linear-gradient(0deg, #5787bd 0%, #5787bd 100%), rgba(31, 90, 166, 0.8);

    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
  }

  &:hover &__times {
    opacity: 0;
  }

  &__times {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 16px;
    padding: 12px;
    background-color: rgb(255 255 255 / 80%);
    border-radius: 16px;

    transition: opacity 0.35s ease-in-out;
  }

  &__time {
    color: #31364e;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;

    span {
      display: inline-block;

      color: #000;
      font-weight: 500;

      width: 3ch;
      margin-right: 5px;
    }
  }

  &:not(:hover) &__navigation {
    opacity: 0;
    visibility: hidden;
  }

  &__navigation {
    position: absolute;
    left: 0;
    bottom: 16px;

    transition: opacity 0.35s ease-in-out;

    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 20px;

    & .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
    }

    & .btn-box-light {
      background-color: #fff;
      color: #5786bd;

      text-decoration: none;

      font-family: Work Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1200px) {
  .upcoming_list {
    grid-template-columns: repeat(3, 1fr);
  }
  // .course-filter .course-card {
  //     height: 380px;
  // }

  .course-card__times {
    padding: 9px;
  }
}

@media screen and (max-width: 991px) {
  .course-card {
    padding: 15px 8px 19px 15px;
    &__type {
      font-size: 9px;
    }

    &__name {
      font-size: 17px;
    }

    &__time {
      font-size: 12px;
    }

    &__date {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .upcoming_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 380px) {
  .upcoming_list {
    grid-template-columns: 1fr;
  }
}
.daterangepicker.openscenter:before {
  display: none;
}
.daterangepicker.openscenter:after {
  display: none;
}

.daterangepicker.opensleft:before {
  display: none;
}
.daterangepicker.opensleft:after {
  display: none;
}
