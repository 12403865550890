main.page--find-a-course {
  @media screen and (max-width: 991px) {
    margin: auto;
  }
  .title-header {
    background-color: #fffaf5;
    padding: 120px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image:
      url(/img/center-header-bg-1.webp), url(/img/center-header-bg-2.webp);
    background-position:
      left center,
      right center;
    background-repeat: no-repeat, no-repeat;
    @media screen and (max-width: 767px) {
      padding: 40px 16px;
      background: #fffaf5;
    }
    .page-title {
      font-family: Lora;
      font-size: 56px;
      line-height: 68px;
      text-align: center;
      font-weight: 400;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 38px;
        font-weight: 400;
      }
    }
    .event-title-info {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 16px;
      line-height: 24px;
      font-size: 18px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .address {
        flex: 100%;
        justify-content: center;
      }
      strong {
        font-weight: 500;
      }
      .icon-aol {
        font-size: 24px;
      }
    }
    .page-description {
      color: #6f7283;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      max-width: 588px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .section-course-find {
    padding: 80px 20px;
    @media screen and (max-width: 991px) {
      padding: 40px 0px;
    }
    .container {
      max-width: 1334px;
      margin: auto;
    }
    .courses-tabs {
      margin-bottom: 48px;
      .tab-links {
        display: flex;
        li {
          border-bottom: 1px solid #e9e9e9;
          display: flex;
          @media screen and (max-width: 991px) {
            flex: 1;
          }
        }
        a {
          font-family: Lora;
          font-size: 24px;
          font-weight: 600;
          line-height: 31.2px;
          padding: 10px 32px 20px;
          color: #9598a6;
          text-decoration: none;
          margin-bottom: -1px;
          border-bottom: 2px solid transparent;
          @media screen and (max-width: 991px) {
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            padding: 10px 0 20px;
            text-align: center;
            flex: 1;
          }
          &.active {
            color: #31364e;
            border-bottom: 2px solid #31364e;
          }
        }
      }
    }
    .course-tab-content-wrap {
      display: flex;
      gap: 48px;
      align-items: flex-start;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 20px;
      }
      &.meetup .course-listing .course-location + .course-instructors {
        border-bottom: none;
        margin-bottom: 0;
      }
      &.events .course-listing .course-item {
        gap: 14px;
        .course-item-header {
          margin: 0;
        }
        .course-date,
        .course-time {
          padding: 0;
          margin: 0;
        }
      }
    }
    .course-filter-wrap {
      background: #f7f7f7;
      padding: 20px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: 316px;
      flex: 1;
      @media screen and (min-width: 992px) {
        position: sticky;
        top: 10px;
      }
      .search_course_form_mobile {
        @media screen and (max-width: 720px) {
          margin-bottom: 0;
          .courses-filter {
            button.btn_outline_box {
              color: #000000;
            }
          }
        }
      }
      .filter-save-button {
        display: none;
      }
      .courses-filter__wrapper-list {
        border: #e9e9e9 1px solid;
        border-radius: 20px !important;
        box-shadow:
          0px 8px 8px -4px rgba(16, 24, 40, 0.031372549),
          0px 20px 24px -4px rgba(16, 24, 40, 0.0784313725) !important;
        width: 100%;
        padding: 8px;
        top: 100% !important;
        .courses-filter__list.active {
          width: 100%;
          .courses-filter__list-item {
            text-align: left;
            &:hover {
              background: #ed994e70;
            }
          }
        }
        .smart-input {
          width: 100%;
          input.custom-input {
            width: 100% !important;
            margin: 0;
          }
          .smart-input--list {
            box-shadow: none;
            border: none;
            margin: 5px 0 0 0 !important;
            padding: 0;
            width: 100%;
            &:empty {
              display: none;
            }
            .smart-input--list-item {
              padding: 12px 20px;
              font-size: 14px;
              &:hover {
                background: #ed994e70;
              }
            }
          }
        }
      }
      .search_course_form_mobile {
        @media screen and (max-width: 720px) {
          margin-bottom: 0;
          .courses-filter {
            button.btn_outline_box {
              color: #000000;
            }
          }
        }
      }
      #courses-filters {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        padding: 0;

        .courses-filter {
          flex-direction: column;
          display: flex;
          flex: 1;
          width: 100%;
          .rs-input::placeholder {
            color: rgb(203, 213, 225);
          }
          .rs-input:focus {
            outline: none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none !important;
          }
          .rs-input {
            color: #31364e;
            border-width: 0px;
            -webkit-tap-highlight-color: transparent;
            user-select: none;
            box-shadow: none;
            height: 100%;
          }
          .rs-picker-error > .rs-input-group {
            border-color: transparent;
          }
          .rs-picker-daterange.rs-picker-subtle .rs-input-group {
            border-color: transparent;
            background-color: transparent;
            outline: none;
          }
          .rs-input-group-addon {
            display: none;
          }
          .rs-picker-error > .rs-input-group {
            border-width: 0px;
          }
          .courses-filter__button.date-picker {
            padding: 0px;
          }
          label {
            margin-bottom: 6px;
          }
          &__button {
            border: #e9e9e9 1px solid;
            padding: 10px 14px;
            border-radius: 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            box-shadow: 0px 1px 2px 0px #1018280d;
            height: 44px;
            text-align: left;
            background: #ffffff url(/img/chevron-down.svg) no-repeat
              calc(100% - 14px) center;
            color: #9598a6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 35px;
          }
          .courses-filter__remove {
            background: none;
            padding: 0;
            height: 20px;
            width: 20px;
            top: auto;
            bottom: 30px;
            right: -7px;
          }
          &.active,
          &.with-selected {
            .courses-filter__button {
              border: #ed994e 1px solid;
              outline: none;
              color: #31364e;
              &::before {
                display: none;
              }
            }
          }
          .courses-filter__wrapper-list {
            border: #e9e9e9 1px solid;
            border-radius: 20px;
            box-shadow:
              0px 8px 8px -4px #10182808,
              0px 20px 24px -4px #10182814;
            top: calc(100% + 12px);
            &::before {
              display: none;
            }
          }
          &.upward {
            .courses-filter__wrapper-list {
              top: auto !important;
              bottom: 44px;
              .smart-input--list {
                order: -1;
                margin: 0 0 5px 0 !important;
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) {
        background: none;
        padding: 0;
        width: 100%;
        max-width: 100%;
        &.open {
          &::after {
            position: fixed;
            content: '';
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 101;
          }
        }
        #courses-filters {
          display: none !important;
          height: 0;
          background: white;
          padding: 0px 16px 90px;
          border-radius: 20px 20px 0 0;
          &.open {
            height: 80vh;
            display: flex !important;
            z-index: 102;
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            overflow: auto;
          }
        }
        .filter-cancel-button {
          width: 32px;
          position: fixed;
          display: flex;
          background: white;
          border: none;
          height: 32px;
          align-items: center;
          justify-content: center;
          right: 10px;
          z-index: 1;
          border-radius: 32px;
          bottom: calc(90vh + 8px);
          z-index: 101;
          &::after,
          &::before {
            content: '';
            height: 2px;
            width: 16px;
            border-radius: 2px;
            background: #6f7283;
            display: block;
            transform: rotate(45deg);
            margin: 0px 8px;
            top: 50%;
            position: absolute;
          }
          &::before {
            transform: rotate(135deg);
          }
          &:focus {
            outline: none;
          }
        }
        .filter-save-button {
          border-radius: 100px;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          border: none;
          color: #fff;
          width: calc(100% - 32px);
          position: fixed;
          bottom: 32px;
          left: 16px;
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          z-index: 1;
        }
      }
      .course-filter-btn,
      .filter--button {
        height: 44px;
        border: #31364e 1.5px solid;
        border-radius: 100px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        background: white;
        color: #31364e;
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        cursor: pointer;
        width: 142px;
        .icon-aol {
          font-size: 20px;
        }
        #filter-count {
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: #ed994e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media screen and (min-width: 992px) {
          display: none;
        }
        &.active {
          &::after {
            position: fixed;
            content: '';
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 101;
          }
        }
      }
      .filter--box {
        height: 90vh;
        display: flex;
        z-index: 102;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        overflow: auto;
        background: white;
        flex-direction: column;
        border-radius: 20px 20px 0 0;
        padding: 0px 16px 90px;
        &[style='display: none;'] + .filter-cancel-button {
          display: none;
        }
        .selected-filter-wrap {
          display: flex;
          margin-top: 30px;
          align-items: flex-start;
          gap: 12px;
          flex-basis: unset;
        }
        .btn_outline_box,
        .mobile-modal--header .custom-dropdown {
          border: #e9e9e9 1px solid;
          padding: 10px 14px;
          border-radius: 16px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          box-shadow: 0px 1px 2px 0px #1018280d;
          height: 44px;
          text-align: left;
          background: #ffffff url(/img/chevron-down.svg) no-repeat
            calc(100% - 14px) center;
          margin-left: 0;
          margin-right: 0;
          margin-top: 6px !important;
          margin-bottom: 20px;
          justify-content: flex-start;
          &::after {
            display: none;
          }
          a.btn {
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            padding: 0;
          }
        }
        .btn_outline_box {
          width: 100%;
        }
        #switch-mobile-filter {
          padding: 3px;
          a.btn {
            background: white;
            text-align: center;
            transition: unset;
            &[data-swicth-active='true'] {
              height: 36px;
              border-radius: 12px;
              line-height: 36px;
              color: #fff;
              background: linear-gradient(
                102.78deg,
                #ffd16f -37.89%,
                #ff865b 100.28%
              );
            }
          }
        }
        .courses-filter {
          &.with-selected {
            .btn_outline_box {
              border: #ed994e 1px solid;
            }
            .courses-filter__remove {
              background: none;
              padding: 0;
              height: 20px;
              width: 20px;
              top: 0;
              bottom: auto;
              right: -7px;
            }
          }
        }
      }
      #time-modal_mobile.mobile-modal {
        display: none;
        &.active {
          display: block;
        }
      }
      .form-item {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #6f7283;
      }
      input,
      select {
        border: #e9e9e9 1px solid;
        padding: 10px 14px;
        background-color: white;
        border-radius: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        box-shadow: 0px 1px 2px 0px #1018280d;
      }
    }
    .course-listing {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      flex: 1;
      .selected-filter-wrap {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      .course-item {
        display: flex;
        flex-direction: column;
        flex: 1 0 48%;
        max-width: 49%;
        background: white;
        box-shadow: 0px 4px 24px 8px #b1aaaa26;
        border-radius: 16px;
        padding: 20px 16px;
        background: #ffffff;
        position: relative;
        @media screen and (max-width: 991px) {
          max-width: 100%;
          flex: 1 0 100%;
        }
        &:hover {
          .course-actions {
            display: flex;
          }
        }
      }
      .course-item-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 14px;
      }
      .course-title-duration {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 8px;
      }
      .course-title {
        font-family: Lora;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 4px;
        flex: 100%;
      }
      .course-type {
        background: #edf5fc;
        border-radius: 8px;
        padding: 5px 12px;
        color: #3d8be8;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        &.online {
          background: #fff5eb;
          color: #ed994e;
        }
      }
      .course-duration {
        background: #edf5fc;
        border-radius: 8px;
        padding: 5px 12px;
        color: #3d8be8;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
      }
      .course-price {
        background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
        border-radius: 8px;
        padding: 4px 12px;
        color: white;
        font-family: Lora;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        flex-shrink: 0;
      }
      .course-university,
      .course-instructors,
      .course-location,
      .course-date,
      .course-time {
        margin-bottom: 14px;
        padding: 14px 0;
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        &::before {
          content: '\e90c';
          font-family: 'iconaol' !important;
          line-height: 16px;
          text-align: center;
          font-size: 16px;
          color: #3d8be8;
          width: 16px;
          height: 16px;
          display: block;
          flex-shrink: 0;
          margin-left: 4px;
          margin-right: 12px;
        }
      }
      .course-university {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          content: '\e94d';
          font-size: 20px;
        }
        & + .course-location {
          border-top: none;
        }
      }
      .course-location {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          content: '\e90b';
        }
        & + .course-instructors {
          padding-top: 12px;
          border-top: none;
        }
      }
      .course-date {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          content: '\e90a';
        }
      }
      .course-time {
        border: none;
        margin-bottom: 14px;
        padding-bottom: 0;
        &::before {
          content: '\e90a';
        }
      }
      .course-timing {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        &::before {
          content: '\e90a';
          font-family: 'iconaol' !important;
          line-height: 16px;
          text-align: center;
          font-size: 16px;
          color: #3d8be8;
          width: 16px;
          height: 16px;
          display: block;
          flex-shrink: 0;
          margin-left: 4px;
          margin-right: 12px;
        }
        & + & {
          margin-top: 6px;
        }
      }
      .event-categories {
        display: flex;
        border-top: #e9e9e9 1px solid;
        padding-top: 14px;
        margin-top: auto;
        .cat-item {
          font-family: Lora;
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          flex: 1;
          & + .cat-item {
            padding-left: 8px;
            border-left: #e9e9e9 1px solid;
          }
        }
      }
      .course-actions {
        background: linear-gradient(
          102.78deg,
          #f5f7f8 -37.89%,
          #ffffff 100.28%
        );
        padding: 24px 20px;
        gap: 12px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-radius: 0 0 16px 16px;
        display: none;
        @media screen and (max-width: 1023px) {
          display: flex;
          padding: 14px 0 0;
          background: none;
          position: relative;
        }
        button {
          flex: 1;
          border-radius: 100px;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          &.btn-secondary {
            border: #ff865b 1.5px solid;
            color: #e47d6c;
            background: #f5f7f8;
          }
          &.btn-primary {
            border: none;
            color: #fff;
            background: linear-gradient(
              102.78deg,
              #ffd16f -37.89%,
              #ff865b 100.28%
            );
          }
        }
      }
      .course-item {
        &.course-full,
        &.registered {
          overflow: hidden;
          position: relative;
          &::after {
            content: 'Course Full';
            display: flex;
            justify-content: center;
            align-items: center;
            width: 171px;
            height: 35px;
            background: #31364e;
            color: #ffffff;
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            transform: rotate(-45deg);
            bottom: 26px;
            right: -40px;
            padding: 0 10px;
            text-align: center;
          }
          .course-price {
            background: #c9c9c9;
          }
          .course-title-duration {
            color: #bdbdbd;
            &::before {
              color: #bdbdbd;
            }
          }
          .course-type {
            color: #bdbdbd;
            background-color: #f2f2f2;
            &::before {
              color: #bdbdbd;
            }
          }
          .course-location,
          .course-instructors,
          .course-timing {
            color: #bdbdbd;
            &::before {
              color: #bdbdbd;
            }
          }
          &:hover {
            .course-actions {
              display: none;
            }
          }
          .course-actions {
            display: none;
            visibility: hidden;
          }
        }
        &.registered {
          &::after {
            content: 'Already Registered';
            background: linear-gradient(
              94.04deg,
              #ffa180 3.84%,
              #ed7347 109.6%
            );
          }
        }
      }
    }
  }
  .selected-filter-wrap {
    display: flex;
    flex-basis: 100%;
    gap: 14px;
    flex-wrap: wrap;
    &:empty {
      display: none;
    }
    .selected-filter-item {
      background: #edf5fc;
      padding: 5px 12px;
      cursor: pointer;
      border-radius: 8px;
      color: #3d8be8;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      position: relative;
      padding-right: 35px;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 13px;
        right: 15px;
        transform: rotate(45deg);
        width: 12px;
        height: 1.5px;
        background: #3d8be8;
      }
      &::after {
        transform: rotate(135deg);
      }
      &.clear {
        background: white;
        color: #31364e;
        &::before,
        &::after {
          background: #31364e;
        }
      }
    }
  }
  .no-course-found-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    margin-top: 150px;
    p {
      color: #6f7283;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

.daterangepicker {
  @media screen and (max-width: 563px) {
    width: 310px;
  }
  border: #e9e9e9 1px solid;
  border-radius: 20px;
  box-shadow:
    0px 8px 8px -4px #10182808,
    0px 20px 24px -4px #10182814;
  font-family: Work Sans;
  &::before,
  &::after {
    display: none;
  }
  .drp-calendar {
    padding: 20px 24px !important;
    max-width: unset;
    @media screen and (max-width: 563px) {
      padding: 10px !important;
    }
    &.right {
      border-left: 1px solid #f7f7f7;
    }
  }
  .calendar-table {
    .table-condensed {
      border-spacing: 0 4px;
      thead {
        tr {
          th {
            &.month {
              font-size: 16px;
              color: #31364e;
              font-weight: 600;
            }
            color: #31364e;
            &.prev,
            &.next {
              span {
                border-color: #6f7283;
              }
            }
            height: 36px;
          }
          &:nth-child(2) {
            th {
              color: #31364e;
              font-weight: 500;
              font-size: 14px;
              background: none;
              padding-top: 12px;
              height: 52px;
              width: 40px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            color: #31364e;
            height: 40px;
            width: 40px;
            min-height: 40px;
            min-width: 40px;
            max-height: 40px;
            max-width: 40px;
            &:hover {
              color: #182230;
              background: #f7f7f7;
              border: none;
              border-radius: 20px;
              font-weight: 500;
            }
            &.off {
              color: #6f7283;
            }
            &.in-range {
              background: #f7f7f7;
              color: #31364e;
              font-weight: 500;
            }
            &.active {
              border-radius: 20px;
              color: #ffffff;
              font-weight: 500;
              background: linear-gradient(
                102.78deg,
                #ffd16f -37.89%,
                #ff865b 100.28%
              );
              border: none;
              position: relative;
              font-weight: 500;
            }
            &.start-date {
              overflow: hidden;
              position: relative;
              &::before,
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;
              }
              &::after {
                bottom: 0;
                right: 0;
                box-shadow: 20px 20px 0 0 #f7f7f7;
              }
              &::before {
                top: 0;
                right: 0;
                box-shadow: 20px -20px 0 0 #f7f7f7;
              }
            }
            &.weekend:last-child {
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
            }
            &.weekend:first-child {
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
            }
          }
        }
      }
    }
  }
}
