.comments {
  padding: 100px 0;
  background-color: #f7f5f4;

  &__video {
    width: 556px;
    height: 309px;
    margin: 60px auto;
    background-color: #e6e4e3;
  }

  &__item {
    padding: 30px 24px;
    min-height: 434px;
    background-color: white;

    &.comment-opacity {
      opacity: 0.6;
    }
  }

  &__person {
    display: flex;
    align-items: center;

    &-img {
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #e6e4e3;
      margin-right: 30px;

      span {
        position: absolute;
        top: 0;
        right: -15px;
        font-size: 60px;
        line-height: 1;
        font-weight: 600;
        color: #31364e;
      }

      &_none {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        color: #31364e !important;
        font-size: 24px !important;
      }
    }

    &-about {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      color: #c4c5cc;
    }
  }

  &__name {
    font-family:
      Work Sans,
      sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: #31364e;
  }

  &__quote {
    max-width: 400px;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    line-height: 1.3;
    color: #31364e;
    margin-top: 20px;
    margin-bottom: 15px;

    &--max-width {
      max-width: 250px;
      margin: auto;
    }
  }

  &__text {
    font-size: 16px;
    font-style: italic;
    line-height: 1.63;
    color: #31364e;
  }

  &__link {
    margin-top: 15px;
  }

  &__title {
    margin: 0;
    &_extra-margin {
      margin-bottom: 60px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    max-width: 555px;
    margin: 20px auto 40px auto;
  }

  &__button-next,
  &__button-prev {
    outline: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    background-image: linear-gradient(to top, #3d8be8, #89beec);
    transition: all 0.3s ease-in-out;

    &:after {
      width: 60px;
      height: 60px;
      top: 50%;
      left: 50%;
    }

    &:hover {
      outline: none;
      background-image:
        linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        linear-gradient(to top, #3d8be8, #89beec);
    }

    &:active {
      outline: none;
      background-image:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to top, #3d8be8, #89beec);
    }
  }

  &__button-next {
    right: 190px;

    &:after {
      content: url('/img/ic-arrow-rigth.svg');
    }
  }

  &__button-prev {
    left: 40px;

    &:after {
      content: url('/img/ic-arrow-left.svg');
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.silent-retreat,
  &.sahaj-samadhi {
    .comments__item {
      min-height: unset;
    }
  }

  &.sahaj-samadhi {
    background: linear-gradient(
      180deg,
      rgba(193, 197, 219, 1) 0%,
      rgba(180, 208, 241, 1) 80%,
      rgba(191, 218, 255, 1) 100%
    );
    background-color: #bfdaff;

    .comments__item {
      border-radius: 8px;
      border: $grey-border;
    }

    .comments__title {
      display: flex;
      justify-content: center;

      span {
        font-size: 20px;
      }

      .sahaj-span {
        display: none;
      }
    }
  }

  &.meditation-page {
    background-color: $white;
  }
}
