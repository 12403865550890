.course-finder-questionnaire-welcome {
  background-image: url(/img/questionnaire-body-bg.webp);
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 129px;
  padding-bottom: 129px;
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
    padding-top: 32px;
  }
  .welcome-box {
    max-width: 1100px;
    margin: auto;
    background-color: white;
    border-radius: 32px;
    padding: 48px 40px;
    box-shadow: 0px 4px 32px 0px #95300017;
    background-image: url(/img/questionnaire-welcome-girl1.webp);
    background-repeat: no-repeat;
    background-position: calc(100% - 45px) 100%;
    background-size: 420px;
    @media screen and (max-width: 991px) {
      padding: 24px 24px 530px 24px;
      background-position: calc(50% + 22px) bottom;
    }
    @media screen and (max-width: 767px) {
      background-size: 300px;
      background-position: calc(50% + 15px) bottom;
      padding-bottom: 400px;
    }
    .info-area {
      max-width: 539px;
      text-align: center;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }
    .logo-wrap {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .logo__image {
      width: 122px;
    }
    h1.title {
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
      margin: 40px auto 32px;
      max-width: 421px;
      @media screen and (max-width: 991px) {
        margin-top: 0;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 12px;
      }
    }
    .description p {
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      color: #6f7283;
      margin: 10px 0;
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }
    .btn-register {
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      height: 54px;
      width: 240px;
      padding: 0px 32px 0px 32px;
      border-radius: 16px;
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      line-height: 54px;
      color: white;
      text-decoration: none;
      margin-top: 40px;
      border-width: 0;
      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.course-finder-questionnaire-question {
  background-image: url(/img/questionnaire-body-bg.webp);
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #fef9f5;
  padding-top: 0px;
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  .back-btn-wrap {
    padding: 30px 0;
    @media screen and (max-width: 991px) {
      padding: 24px 0;
    }
  }
  .back-btn {
    width: 111px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #31364e;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #31364e;
    text-decoration: none;
    gap: 8px;
    background-color: transparent;
  }
  .question-box {
    box-shadow: 0px 4px 32px 0px #95300017;
    background-color: white;
    padding: 40px;
    border-radius: 30px;
    max-width: 800px;
    margin: auto;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      padding: 24px 16px;
    }
  }
  .question-step-highlighter-wrap {
    display: flex;
    gap: 10px;
    .question-step-highlighter {
      height: 9px;
      background: #e9e9e9;
      border-radius: 20px;
      flex: 1;
      &.active {
        background: linear-gradient(
          102.78deg,
          #3d8be8 -37.89%,
          #89beec 100.28%
        );
      }
    }
  }
  .question-title {
    font-family: Lora;
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
    margin: 12px 0;
    @media screen and (max-width: 991px) {
      margin: 24px 0 20px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  .question-subtitle {
    font-family: Lora;
    font-size: 20px;
    line-height: 38px;
    font-weight: 400;
    margin: 32px 0;
    @media screen and (max-width: 991px) {
      margin: 24px 0 20px;
      font-size: 16px;
      line-height: 34px;
    }
  }
  .question-description {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-top: -20px;
    margin-bottom: 32px;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
      margin-top: -8px;
    }
  }
  .question-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
    .option-item {
      input[type='checkbox'],
      input[type='radio'] {
        opacity: 0;
        position: absolute;
        &:checked {
          & + label {
            border: 2px solid #ff865b;
            background: white;
          }
        }
      }
      label {
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background:
          linear-gradient(0deg, #f7f7f7, #f7f7f7),
          linear-gradient(0deg, #e9e9e9, #e9e9e9);
        cursor: pointer;
        height: 44px;
      }
    }
  }
  .question-action {
    margin-top: auto;
    .btn-register {
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      height: 54px;
      width: 100%;
      padding: 0px 32px 0px 32px;
      border-radius: 16px;
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      line-height: 54px;
      color: white;
      text-decoration: none;
      text-align: center;
      border-width: 0;
      @media screen and (max-width: 991px) {
        height: 48px;
        font-size: 16px;
        line-height: 48px;
      }
      &:disabled {
        cursor: not-allowed;
        background: #f7f5f4;
        color: #b8b8b8;
      }
    }
  }
  .questions-info {
    background: #fffaf5;
    border-radius: 16px;
    padding: 16px;
    .info-text {
      max-width: 564px;
      margin: auto;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: center;
      color: #6f7283;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    label {
      font-size: 14px;
      color: #9598a6;
      margin-bottom: 6px;
    }
    select {
      padding: 10px 14px;
      height: 44px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
    input:not(
        [type='file' i],
        [type='image' i],
        [type='checkbox' i],
        [type='radio' i]
      ) {
      padding: 10px 14px;
      height: 44px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      box-shadow: 0px 1px 2px 0px #1018280d;
      width: 100%;
      &:focus {
        border-color: #ed994e;
      }
      &::placeholder {
        color: #9598a6;
        opacity: 1; /* Firefox */
      }
      &.error {
        border-color: red;
      }
      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #9598a6;
      }
      a {
        text-decoration: none;
        font-size: 26px;
      }
    }
    &.checkbox,
    &.radio {
      flex-direction: row;
      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        & + label {
          &::before {
            content: '';
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: #ed994e 1px solid;
            border-radius: 4px;
            margin-right: 12px;
            display: flex;
            font-weight: 900;
            justify-content: center;
            align-items: center;
          }
          a {
            display: contents;
            color: #ed994e;
          }
        }
        &:checked + label {
          &::before {
            content: url(/img/checkicon-css.svg);
            background-color: #ed994e;
            color: white;
          }
        }
      }
      input[type='radio'] {
        & + label {
          &::before {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: #ed994e 1.5px solid;
            border-radius: 20px;
          }
        }
        &:checked + label {
          &::before {
            content: '\25cf';
            background-color: white;
            color: #ed994e;
            font-size: 27px;
            line-height: unset;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 0px 0 2px 1px;
          }
        }
      }
      label {
        padding-left: 0;
        display: flex;
        align-items: center;
        color: #31364e;
      }
    }
  }
}
