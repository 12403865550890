.header {
  text-align: center;
  border-bottom: 1px solid $border-color;
  background: white;
  width: 100%;
  z-index: 25;

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding: 13px 60px;
    @media screen and (max-width: 720px) {
      padding-top: 5px;
      padding-bottom: 6px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media screen and (min-width: 992px) {
      .mobile-menu-buttons {
        display: none;
      }
      .navbar-collapse.collapse {
        .nav-logowrap {
          display: none;
        }
      }
    }
    .navbar-nav {
      @media screen and (min-width: 992px) {
        height: 38px;
        li.nav-item {
          height: 68px;
        }
      }
      &-right {
        justify-content: flex-end;
      }
    }
  }

  .nav-link {
    text-decoration: none;
    text-align: left;
    font-size: 15px;
  }

  .dropdown-item {
    text-decoration: none;
  }

  button.navbar-toggler-header {
    border: none;

    &:focus {
      outline: none;
    }
  }

  .dropdown-toggle {
    color: $text-color;

    &-dop {
      &:after {
        position: static;
        margin-top: 7px;
      }
    }

    &:hover {
      color: $grey;
    }

    &:after {
      color: $light-grey-2;
    }
  }

  .navbar-brand {
    padding: 0;
  }

  .nav-item {
    font-weight: 600;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &.active {
      .nav-link {
        color: $text-color;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 10px 60px;
  }

  &__sidebar-button {
    margin-left: 50px;
  }

  .logo {
    position: relative;
    height: 45px;
    width: 115px;

    &:has(img[src='/img/PWHT-logo.png']) {
      width: 220px;
      height: auto;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &_desktop,
      &_mobile {
        display: none;
      }
    }

    &_big {
      width: 230px;
    }
  }

  .menu {
    &__list {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      .menu-item {
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: 40px;
        }

        &__link {
          color: $text-color;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;
          padding: 10px 8px;
        }

        &__arrow {
          position: relative;
          z-index: 115;
          margin-top: -24px;
          margin-left: 35%;
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 11px solid $dark-blue;
          display: none;
          top: 40px;
        }

        .sub-menu {
          width: 100%;
          top: 75px;
          background: $dark-blue;
          z-index: 151;
          color: white;
          position: absolute;
          left: 0;
          display: none;
          border-bottom: solid 3px $dark-yellow;
          padding: 10px 60px;

          &__list {
            display: flex;

            .sub-menu-item {
              &:not(:last-child) {
                margin-right: 20px;
              }

              &__link {
                text-transform: initial;
                text-decoration: none;
                color: white;

                &:hover {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    &__items {
      .dropdown {
        display: inline-block;
        margin-right: 40px;

        &-toggle {
          color: $text-color;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;

          &::before {
            position: absolute;
            content: '';
            display: block;
            height: 2px;
            width: 0;
            bottom: -3px;
            background: $orange;
            left: 0;
            transition: width 0.3s ease-in-out;
          }

          &:hover {
            color: $yellow;

            &:after {
              color: $yellow;
            }
          }
        }

        &-menu {
          margin-top: 6px;
          padding: 5px 0;
          border-color: $border-color;
          border-radius: 12px;
          box-shadow: 0px 5px 25px transparentize($blue, 0.8);

          .dropdown-item {
            color: $text-color;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            padding: 10px 20px;
            text-transform: uppercase;

            &:hover {
              background-color: transparentize($orange, 0.85);
            }
          }
        }

        &.show {
          .dropdown-toggle {
            color: $orange;

            &:before {
              width: 85%;
              width: calc(100% - 12px);
            }

            &:after {
              color: $orange;
              transform: rotate(-180deg) translateY(2px);
            }
          }
        }
      }
    }
  }

  &__button {
    font-size: 16px !important;
    line-height: 22px !important;
    height: auto !important;
    margin: 0 0 0 auto;
  }

  &_simple {
    .header__container {
      padding: 25px 15px;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.auth-header {
  height: 76px;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 10;
  //border-bottom: 1px solid #e9e9e9;

  &__logo img {
    width: 100%;
    height: 100%;
  }

  &__btn-sidebar {
    display: none;
  }

  &__container {
    max-width: 1212px;
    padding: 0 20px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: 1px;
    height: 36px;
    background-color: $border-color;
    margin: 0 28px;
  }
}

.auth-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 28px;

  &__link {
    position: relative;
    padding-right: 18px;
    cursor: pointer;

    &:before {
      position: absolute;
      right: 6.5px;
      top: 55%;
      content: url('/img/ic-arrow-gray.svg');
      line-height: 0;
      transform: translate(50%, -50%);
    }

    & a {
      font: 600 14px/16px 'Work Sans';
      color: $text-color;
      text-decoration: none;

      &:hover {
        color: $text-color;
      }
    }

    &:hover {
      &:before {
        transform: translate(50%, -50%) rotate(180deg);
      }

      .auth-menu__submenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__submenu {
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $light-grey-2;
    width: auto;
    min-width: 100%;
    padding: 12px 18px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    &-link {
      display: flex;
      white-space: nowrap;
    }
  }
}

.header-user {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $border-color;
    font: 500 12px/16px 'Work Sans';
    color: $light-grey;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__name {
    font: 600 14px/16px 'Work Sans';
  }
}

.header-v2 {
  z-index: 101;
  @media screen and (max-width: 991px) {
    position: relative !important;
    &.menu-opened {
      .navbar-toggler {
        position: fixed;
        right: 15px;
      }
    }
  }
  .header__container {
    flex-direction: row;
    padding: 15px 0;
    justify-content: space-between;
    display: flex;
    @media (width >= 992px) and (width <= 1199px) {
      max-width: unset;
      padding-left: 15px;
      padding-right: 15px;
    }
    .logo {
      @media screen and (max-width: 991px) {
        width: 120px;
        height: 46px;
        order: 2;
      }
    }
    .desktopView {
      display: inline-block;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .mobileView {
      display: none;
      @media screen and (max-width: 991px) {
        display: inline-block;
      }
    }
    @media screen and (max-width: 991px) {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .navbar {
    padding: 0;
    z-index: 101;
    @media (width >= 992px) and (width <= 1199px) {
      flex: 1;
    }
    .navbar-nav {
      gap: 28px;
      @media (width >= 992px) and (width <= 1199px) {
        flex: 1;
        gap: 0;
        justify-content: space-evenly;
      }
      @media screen and (max-width: 991px) {
        gap: 0px;
      }
      .nav-item {
        .back-link {
          display: none;
        }
        &:not(:last-child) {
          margin: 0;
        }
        .dropdown-menu {
          border-radius: 16px;
          border: none;
          box-shadow: 0px 4px 6px -2px #10182808;
          box-shadow: 0px 12px 16px -4px #10182814;
          @media screen and (min-width: 992px) {
            transform: translateX(-33.33%);
          }
          .dropdown-menu-col {
            @media screen and (max-width: 991px) {
              margin-bottom: 10px;
              display: flex;
              flex-direction: column;
              &::before {
                content: attr(data-title);
                padding: 0 20px;
                margin-bottom: 12px;
                line-height: 28px;
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
          &-inner {
            display: flex;
            @media screen and (max-width: 991px) {
              flex-direction: column;
              overflow: auto;
              height: 100%;
              padding-bottom: 40px;
              &::before {
                content: attr(data-title);
                padding: 0 20px;
                line-height: 28px;
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
          .dropdown-header {
            font-size: 12px;
            text-transform: uppercase;
            color: #6f7283;
            font-weight: 400;
            padding: 8px 16px;
            line-height: 20px;
            @media screen and (max-width: 991px) {
              padding-left: 20px;
              padding-right: 20px;
            }
            &.pt-2rem {
              padding-top: 1.7rem !important;
            }
            @media screen and (max-width: 991px) {
              color: #9f9f9f;
              font-weight: 400;
            }
            @media screen and (min-width: 991px) {
              & + .dropdown-item {
                width: 250px;
              }
            }
          }
          .dropdown-item {
            font-size: 16px;
            font-weight: 400;
            padding: 0 16px;
            margin: 4px 0;
            line-height: 24px;
            color: #31364e;
            cursor: pointer;
            & + .dropdown-item {
              margin-top: 8px;
            }
            &.active {
              background-color: transparent;
            }
            &:hover {
              background-color: transparent;
              color: #ff9200;
            }
            @media screen and (max-width: 991px) {
              color: #595858;
              padding: 0 20px;
            }
            &.pt25 {
              padding-top: 25px;
            }
          }
        }
        @media screen and (min-width: 992px) {
          .dropdown-menu.show {
            display: none;
          }
          &:hover {
            .dropdown-menu {
              display: flex !important;

              .dropdown-menu-col {
                /*min-width: 251px;*/
                padding: 0;
              }
              .dropdown-item {
                white-space: nowrap;
                & + .dropdown-item {
                  margin-top: 8px;
                }
                &.active {
                  background-color: transparent;
                  color: #ff9200;
                }
                &.help {
                  color: #3d8be8;
                }
              }
              top: 64px;
              padding-top: 12px;
              padding-bottom: 12px;
              padding-left: 16px;
              padding-right: 16px;
            }
          }
        }
        &.active {
          .nav-link {
            color: #ff9200;
          }
        }
      }
      .nav-link {
        font-weight: 400;
        color: #31364e;
        &:hover {
          color: #ff9200;
        }
        @media screen and (max-width: 1300px) {
          padding: 0.5rem !important;
          font-size: 15px !important;
        }
        @media screen and (min-width: 992px) {
          &::after {
            display: none;
          }
        }
      }
      .donate-link {
        display: flex;
        font-weight: 500;
        img {
          margin-right: 0.5rem;
        }
      }
    }
    .navbar-toggler-header {
      border: none;
      background: transparent !important;
      z-index: 1;
      &:hover {
        background: transparent !important;
      }
      @media screen and (min-width: 992px) {
        display: none;
      }

      .icon-bar {
        width: 25px;
        transition: all 0.2s;
        display: block;
        height: 2px;
        border-radius: 3px;
        background-color: #000;
        & + .icon-bar {
          margin-top: 5px;
        }
      }
      &:not(.collapsed) {
        .top-bar {
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }
        .middle-bar {
          opacity: 0;
        }
        .bottom-bar {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        opacity: 1;
      }
      .bottom-bar {
        transform: rotate(0);
      }
    }
  }
  @media screen and (max-width: 991px) {
    .header__button {
      display: none;
      display: flex;
    }
    .navbar {
      order: 3;
      .navbar-nav {
        padding: 38px 20px;
        width: 100% !important;
        max-height: calc(100vh - 180px);
        overflow: auto;
        .nav-item {
          padding-top: 14px;
          padding-bottom: 14px;
          border-bottom: #e9e9e9 1px solid;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          &:not(:last-child) {
            margin-right: 0;
          }
          .dropdown-menu {
            border-radius: 0;
            border: none;
            box-shadow: none;
            position: fixed;
            padding-top: 67px;
            z-index: 1;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100vh;
            transition: all 0.3s ease-in-out;
            display: block;
            &.show {
              left: 56px;
              .back-link {
                left: 76px;
              }
            }
            .dropdown-item {
              color: #31364e;
              font-size: 16px;
              & + .dropdown-item {
                margin-top: 4px;
              }
              &.active {
                color: #ed994e;
              }
              &.help {
                color: #3d8be8;
              }
            }
          }
          .back-link {
            display: block;
            position: fixed;
            top: 20px;
            left: 100%;
            z-index: 1;
            border: none;
            padding: 0;
            background-color: transparent;
          }
          .nav-link {
            padding: 0px !important;
            line-height: 28px;
            font-size: 20px !important;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            &.dropdown-toggle {
              &::after {
                background: url(/img/BackArrow.svg) no-repeat center;
                height: 20px;
                width: 20px;
                border: none;
                transform: rotate(180deg);
                margin: 0;
                display: block;
              }
            }
          }
          .dropdown-toggle {
            &::after {
              display: none;
            }
          }
        }
      }
      &:has(.navbar-collapse.collapse.show) {
        &::before {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          content: '';
          display: block;
        }
      }
      .mobile-menu-buttons {
        margin-top: auto;
        bottom: 0;
        position: absolute;
        padding: 0 20px 52px;
        width: 100%;
        .login-btn {
          border: #31364e 1px solid;
          border-radius: 40px;
          height: 40px !important;
          color: #31364e;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      .header__container {
        flex-direction: unset;
      }
      .navbar-collapse {
        position: fixed;
        width: calc(100vw - 56px);
        left: 100%;
        top: 0;
        background-color: #ffffff;
        transition: all 0.3s ease;
        &.show {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          left: 56px;
        }
        @media screen and (max-width: 991px) {
          height: 100vh !important;
          overflow: auto;
        }
        .nav-logowrap {
          padding-left: 20px;
          padding-top: 8px;
        }
      }
    }
  }
  .user-profile-link {
    position: relative;
    img {
      border: 0;
      width: 24px;
      margin-right: 0.5rem;
    }
    .UserprofileView {
      display: flex;
      align-items: center;
      .username {
        font: 600 16px 'Work Sans';
        @media screen and (max-width: 1199px) {
          display: none;
        }
      }
      .header__button {
        height: 40px !important;
        font-size: 15px !important;
        font-weight: 500;
        border-radius: 40px;
        padding: 0 24px;
        white-space: nowrap;
        @media (width >= 992px) and (width <= 1199px) {
          padding: 0 10px;
        }
      }
    }
    .header_profileHeaderImage {
      border-radius: 50%;
      box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
      color: #ffffff;
      font-family: Lora, sans-serif;
      font-size: 16px;
      font-weight: 700;
      height: 2.375rem;
      margin: 4px 10px 0;
      width: 2.375rem;
      img {
        width: 2.375rem;
        height: 2.375rem;
        margin-right: 0;
      }
    }
    .initials {
      display: flex;
      position: absolute;
      width: 2.375rem;
      height: 2.375rem;
      justify-content: center;
      align-items: center;
      background-color: #ef6c00;
      border-radius: 50%;
    }
    @media screen and (max-width: 991px) {
      order: 1;
      margin: unset;
      padding: 0;
      &.hide-link {
        display: none;
      }
    }
  }
  .resourceNav {
    .navbar-nav {
      width: auto !important;
    }
  }
  .desktopView {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
    .resources {
      font-weight: 400;
      color: #000000;
      margin-right: 10px;
    }
  }
}

@import './header-media';
