.ask-gurudev-page {
  background: #fffaf5;
  padding-bottom: 100px;
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  .toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0.9;
    animation: fadeInOut 2s;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.9;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  .youtubeButton {
    display: block;
    position: relative;
    width: 68px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
    border: none;
  }

  .ask-gurudev-top {
    padding-top: 60px;
    padding-bottom: 40px;
    @media screen and (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 24px;
    }
    .container {
      max-width: 1336px;
    }
  }
  .page-title {
    font-family: Lora;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    text-align: center;
    margin-bottom: 0px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 0px;
    }
    &.beta {
      position: relative;
      &:after {
        content: 'BETA';
        font-family: 'Work Sans';
        font-size: 12px;
        color: #3d8be8;
        border: 1px solid #3d8be8;
        border-radius: 4px;
        padding: 1px 4px;
        position: absolute;
        top: 0;
        height: 17px;
        line-height: 13px;
        margin-left: 5px;
        font-weight: 600;
      }
    }
  }
  .page-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #6f7283;
    strong {
      font-weight: 500;
    }
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
  .ask-gurudev-main-search {
    .main-search-area {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
    .search-input-box {
      display: flex;
      justify-content: center;
      position: relative;
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-width: 744px;
      .clear-button {
        position: absolute;
        right: 55px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        visibility: hidden;
        cursor: pointer;
        height: 24px;
        width: 24px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 991px) {
          height: 20px;
          width: 20px;
          right: 50px;
        }
        img {
          width: 100%;
        }
      }
      input {
        border: #f2cbab 1px solid;
        box-shadow: 0px 3px 23px 0px #95300014;
        border-radius: 360px;
        height: 64px;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        padding: 0 95px 0 32px;
        max-width: 744px;
        @media screen and (max-width: 991px) {
          background-position: calc(100% - 20px) center;
          background-size: 20px;
          padding: 0 75px 0 20px;
          font-size: 16px;
          font-weight: 400;
        }
        &.has-value + .clear-button {
          visibility: visible;
        }
      }
      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        display: none;
      }
    }
    .search-button {
      position: absolute;
      right: 16px;
      top: 51%;
      transform: translateY(-50%);
      border: none;
      background: none;
      cursor: pointer;
      height: 32px;
      width: 32px;
      background-size: contain;
      @media screen and (max-width: 991px) {
        padding: 0;
        img {
          width: 20px;
        }
      }
    }

    .search-tags {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width: 991px) {
        gap: 8px;
      }
      .search-tag {
        border: #ed7347 1px solid;
        box-shadow: 0px 7px 12px 0px #9530000f;
        background: white;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        border-radius: 38px;
        cursor: pointer;
        @media screen and (max-width: 991px) {
          padding: 8px;
          font-size: 12px;
        }
      }
    }
  }
  .search-results-area {
    .container {
      padding-top: 32px;
      max-width: 1090px;
      @media screen and (max-width: 991px) {
        max-width: 100%;
        padding-top: 20px;
      }
    }
    .vote-up-down {
      box-shadow: 0px 7px 12px 0px #9530000f;
      background: white;
      border: #e9e9e9 1px solid;
      border-radius: 360px;
      display: flex;
      height: 56px;
      align-items: center;
      @media screen and (max-width: 991px) {
        height: 48px;
        order: 1;
      }
      label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 0 16px;
        @media screen and (max-width: 991px) {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
      .vote-actions {
        border-left: #e9e9e9 1px solid;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0 18px 0 16px;
        button {
          height: 20px;
          width: 20px;
          border: none;
          background: none;
          padding: 0;
          display: flex;
          &.active {
            path {
              stroke: #45b26b;
            }
          }
        }
      }
    }
    .answer-bottom-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 20px;
      }

      .view-other-answers {
        margin-right: 32px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: #3d8be8;
        margin-left: auto;
        border-bottom: 1.5px solid #3d8be8;
        @media screen and (max-width: 991px) {
          margin: auto;
          order: 1;
        }
        a {
          color: #3d8be8;
          text-decoration: none;
        }
      }
      .answer-pagination {
        ul {
          display: flex;
        }
        ul,
        li {
          margin: 0;
          padding: 0;
        }
        a {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          border: transparent 1px solid;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6f7283;
          &.active {
            border-color: #e9e9e9;
            box-shadow: 0px 1px 2px 0px #1018280d;
            background: white;
            color: #31364e;
          }
        }
      }
    }
    .related-questions-wrap {
      margin-top: 32px;
      /*box-shadow: 0px 12px 16px -4px #00000014;*/
      padding: 0 12px;
      margin-bottom: 12px;
      border-radius: 0;
      background-color: white;
      border-top: #e9e9e9 1px solid;
      padding-top: 32px;
      @media screen and (max-width: 991px) {
        padding: 32px 5px 0;
      }
      .box-title {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        gap: 12px;
        align-items: center;
        .icon-aol {
          color: #3d8be8;
        }
        @media screen and (max-width: 991px) {
          font-size: 20px;
        }
      }
      .accordion {
        padding-top: 16px;
        @media screen and (max-width: 991px) {
          padding-top: 4px;
        }
      }
      .question-item {
        border-bottom: #e9e9e9 1px solid;
        padding: 16px 0;
        &:last-child {
          border: none;
          padding-bottom: 0;
        }
      }
      .question-header {
        button {
          padding: 0;
          color: #31364e;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }
          &:hover,
          &:focus {
            text-decoration: none;
          }
          &:after {
            content: '+';
            font-weight: 300;
            font-size: 24px;
            line-height: 24px;
            margin-left: auto;
          }
          &[aria-expanded='true'] {
            &::after {
              content: '-';
              font-size: 28px;
              line-height: 20px;
              margin-top: -3px;
            }
          }
        }
      }
      .question-body {
        padding-top: 16px;
      }
    }
  }
  .section-title {
    font-size: 32px;
    line-height: 39px;
    font-weight: 500;
    text-align: center;
    margin: 0;
    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  .category-tabs-wrap {
    padding: 15px 22px;
    background: #ffffff;
    border-radius: 16px;
    margin-top: 32px;
    &::after {
      content: '';
      height: 1px;
      background: #e9e9e9;
      width: calc(100% - 44px);
      position: absolute;
      bottom: 14px;
      left: 22px;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    @media screen and (max-width: 991px) {
      border-radius: 0;
      margin: 24px -15px 0;
      box-shadow: 0px 1px 3px 0px #0000001a;
      padding: 0;
    }
    .swiper-slide {
      width: auto;
      min-width: 60px;
    }
    .swiper-pagination {
      top: 0;
      left: 0;
      width: 100%;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .slide-button {
      width: 32px;
      height: 32px;
      border: #e9e9e9 1px solid;
      border-radius: 100%;
      background: white;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 40px;
      transform: translateY(-50%);
      position: absolute;
      left: 10px;
      z-index: 9;
      &.slide-button-next {
        right: 10px;
        left: auto;
      }
      &.swiper-button-disabled {
        visibility: hidden;
      }
    }
  }
  .category-tabs {
    @media screen and (max-width: 991px) {
      border-bottom: none;
      padding: 8px 15px 0;
    }
    .nav-link {
      border: none;
      display: flex;
      flex-direction: column;
      background: none;
      align-items: center;
      text-align: center;
      gap: 7px;
      color: #6f7283;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 10px;
      border-bottom: transparent 2px solid;
      margin-bottom: -1px;
      width: 138px;
      flex-shrink: 0;
      text-decoration: none;
      @media screen and (max-width: 991px) {
        gap: 6px;
        font-size: 12px;
        line-height: 18px;
        width: auto;
        padding-left: 0;
        padding-right: 0;
      }
      .icon-aol {
        font-size: 32px;
        @media screen and (max-width: 991px) {
          font-size: 24px;
        }
      }
      &.active {
        color: #31364e;
        border-bottom-color: #ed994e;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .categories-tab-content {
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
      margin-bottom: 32px;
    }
    .tab-pane {
      padding: 20px;
      background: white;
      border-radius: 16px;
      box-shadow:
        0px 4px 6px -2px #00000008,
        0px 12px 16px -4px #00000014;
      @media screen and (max-width: 991px) {
        padding-left: 11px;
        padding-right: 11px;
      }
      &.text-video {
        padding: 0;
        background: none;
        border-radius: 0;
        box-shadow: unset;
      }
    }
    .tab-content-video {
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      width: 100%;
      min-height: 410px;
      text-align: center;
      .youtube-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .youtube-iframe iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
    .tab-question {
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
      font-family: Lora;
      margin-bottom: 20px;
      padding: 12px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 36px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0;
      }
    }
    .tab-answer-precontent {
      padding: 0 12px;
      @media screen and (max-width: 991px) {
        padding: 0 5px;
      }
      label {
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 20px;
        display: flex;
        gap: 12px;
        align-items: center;
        @media screen and (max-width: 991px) {
          font-size: 20px;
        }
        .icon-aol {
          color: #3d8be8;
        }
      }
      .pretext {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #6f7283;
        font-style: italic;
        @media screen and (max-width: 991px) {
          font-size: 16px;
          margin-bottom: 11px;
        }
      }
    }
    .tab-content-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      padding: 12px 12px 10px;
      @media screen and (max-width: 991px) {
        padding: 5px;
        font-size: 16px;
      }
      p + p {
        margin-top: 12px;
      }
    }
    .tab-content-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      padding: 14px 12px;
      @media screen and (max-width: 991px) {
        padding: 14px 5px 0;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
      }
    }
    .source-info {
      color: #6f7283;
      font-size: 16px;
      line-height: 24px;
      font-style: italic;
      strong {
        font-weight: 500;
      }
    }
    .tab-content-voting {
      display: flex;
    }
    .tab-content-action {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      .tc-action-btn {
        box-shadow: 0px 1px 2px 0px #1018280d;
        border: #e9e9e9 1px solid;
        border-radius: 16px;
        background: white;
        height: 48px;
        width: 48px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 991px) {
          height: 44px;
          width: 44px;
          font-size: 20px;
        }
        .iconaol-tick-circle {
          color: #45b26b;
        }
      }
    }
  }
  .answer-tab-content {
    padding: 20px;
    background: white;
    border-radius: 16px;
    box-shadow:
      0px 4px 6px -2px #00000008,
      0px 12px 16px -4px #00000014;
  }
  .answer-tabs {
    margin-bottom: 32px;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
    .answer-tab-links {
      display: flex;
      li {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        flex: 1;
      }
      a {
        font-family: Lora;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.2px;
        padding: 0px 32px 20px;
        color: #9598a6;
        text-decoration: none;
        margin-bottom: -1px;
        border-bottom: 2px solid transparent;
        flex: 1;
        text-align: center;
        @media screen and (max-width: 991px) {
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          padding: 0px 0 10px;
        }
        &.active {
          color: #31364e;
          border-bottom: 2px solid #31364e;
        }
      }
    }
  }
  .instagram-video-container {
    width: 100%;
    max-width: 100%;
    height: 495px; /* Fixed height */
    overflow: hidden;
    position: relative;
  }

  .instagram-video-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure content fits without cropping */
  }
  .copy-message {
    box-shadow: 0px 4px 24px 8px #b1aaaa26;
    border: #dbebf9 1px solid;
    background: #edf5fc;
    padding: 12px 24px;
    border-radius: 16px;
    display: flex;
    gap: 12px;
    color: #3d8be8;
    position: fixed;
    top: 128px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-out;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
    .icon-aol {
      font-size: 24px;
    }
    &.hide {
      opacity: 0;
    }
  }
  .share-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    display: flex;
    .share-popup-content {
      background: white;
      padding: 40px;
      border-radius: 20px;
      width: 574px;
      max-width: 90%;
      position: relative;
      text-align: center;
    }
    h3 {
      font-size: 28px;
      line-height: 36.4px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 24px;
    }
    .copy-link-container {
      position: relative;
      #copy-input {
        height: 48px;
        border-radius: 16px;
        border: #e9e9e9 1px solid;
        padding: 10px 44px 10px 14px;
        color: #31364e;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
      .copy-button {
        border: none;
        background: none;
        font-size: 16px;
        padding: 0;
        position: absolute;
        right: 16px;
        top: 16px;
        line-height: 16px;
        color: #3d8be8;
      }
    }
    .close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #9598a6;
      font-size: 24px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .share-icons {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
    .share-icon {
      display: block;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #31364e;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
