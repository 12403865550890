.top-column {
  &.meditation-page {
    background-image: url('/img/meditation-page-first@2x.png');
    background-position-y: 100%;
    box-shadow: none;
    padding: 119px 150px 190px 150px;
    @media screen and (max-width: 1024px) {
      padding: 80px 40px;
    }
    .container {
      text-align: left;
      margin: 0;
      padding: 0;

      .type-course {
        // line-height: 1.56;
        // padding-bottom: 0;
        // margin-bottom: 0;
        font-weight: 600;
      }

      .course-name {
        font-weight: normal;
        line-height: 1.33;
        margin-bottom: 12px;
      }

      .type-guide {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 40px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .ic-play {
        width: 60px;
        height: 60px;
      }
    }
    &.insight-collection {
      background-image: url('/img/bg-insight-collection.png');
      margin-top: 0;
      padding: 91px 150px 100px 150px;
      @media screen and (max-width: 1024px) {
        padding: 80px 40px !important;
      }
      @media screen and (max-width: 768px) {
        padding: 50px 15px !important;
      }
      .container-fluid {
        .row {
          .col-12 {
            padding: 0;
            .type-guide {
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
              margin-bottom: 10px;
              img {
                margin-right: 15px;
              }
            }
            .course-description {
              max-width: 458px;
              font-size: 14px;
              line-height: 22px;
            }
          }
          .swiper-container {
            width: 100%;
          }
        }
      }
      .collection-video {
        box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
        border-radius: 16px;
        @media screen and (max-width: 767px) {
          margin: 40px 0 60px;
        }
        &:hover {
          .video-insighter-progress .loaded,
          .video-insighter-progress .played {
            height: 4px !important;
          }
          .video-insighter-progress .played {
            &:after {
              top: -7px !important;
            }
          }
        }

        .video-insighter-bar:hover {
          .video-time-hint {
            display: block;
          }
          .video-insighter-progress {
            .played {
              &:after {
                opacity: 1 !important;
              }
            }
          }
        }

        .video-player {
          .video-shrink {
            display: none;
            position: fixed;
            top: 30px;
            right: 82px;
            z-index: 2;
            cursor: pointer;
            border: 0;
            padding: 0;
            font-size: 0;
            background-color: #fff;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            img {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .video-close {
            display: none;
            position: fixed;
            top: 30px;
            right: 30px;
            z-index: 2;
            color: #6f7283;
            width: 32px;
            height: 32px;
            background-color: #f7f7f7;
            border-radius: 50%;
            padding: 0;
            margin: 0;
            opacity: 1;
          }
          &.full {
            .video-shrink {
              display: block;
            }
            .video-close {
              display: block;
            }
            .video-insighter-container {
              position: fixed;
              border-radius: 0;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 2;
              font-size: 0;
              background-color: #000;
              video {
                height: 100%;
              }
            }
            .video-insighter-bar {
              position: fixed;
              left: 0;
              right: 0;
              bottom: 20px;
              z-index: 2;
              width: 100%;
            }
            .video-insighter-play {
              display: block !important;
            }
          }
        }
        .video-insighter-container {
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          position: relative;
          font-size: 0;
          cursor: pointer;
          video {
            width: 101%;
            height: auto;
            margin-left: -0.5%;
          }
          .video-insighter-play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-out;

            &.hidden {
              opacity: 0;
            }
          }
          &:hover {
            img {
              content: url('/img/ic-play-hover.svg');
            }
          }
          &:active {
            img {
              content: url('/img/ic-play-active.svg');
            }
          }
        }
        .video-insighter-bar {
          position: relative;
          height: 5px;
          margin-top: -5px;
          z-index: 100;

          &.hidden {
            opacity: 0;
          }
          .video-insighter-progress {
            position: absolute;
            left: 0;
            bottom: 8px;
            margin: 0;
            width: 100%;
            z-index: 999;
            transition: all 0.15s ease-out;
            cursor: pointer;
            & > div {
              position: absolute;
              left: 0;
              top: 4px;
            }
            .loaded {
              z-index: 1;
              height: 4px;
              background-color: #31364e;
              opacity: 0.2;
              border-radius: 2px;
              transition: all 0.15s ease-out;
            }
            .played {
              flex-direction: row-reverse;
              z-index: 2;
              height: 4px;
              background-color: #3d8be8;
              cursor: pointer;
              border-radius: 2px;
              max-width: 100%;
              transition: all 0.15s ease-out;
              &:after {
                content: '';
                display: flex;
                position: absolute;
                box-sizing: border-box;
                top: -9px;
                right: -7px;
                margin-right: -4px;
                width: 25px;
                height: 25px;
                border-radius: 25px;
                background-color: #fff;
                box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                border: solid 1px #e9e9e9;
                opacity: 0;
              }
            }
            &:hover {
              .played {
                &:after {
                  box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                  border: solid 1px #89beec;
                }
              }
            }
            &:focus {
              .played {
                &:after {
                  box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                  border: solid 1px #3d8be8;
                }
              }
            }
          }
        }
        .collection-video-details {
          span {
            color: #31364e;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 5px;
            display: block;
          }
          p {
            font-size: 16px;
            line-height: 1.38;
          }
          text-align: left;
          padding: 20px 24px 30px 24px;
        }
      }
      &.insight-collection2 {
        background-image: url('/img/bg-insight-collection2.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top;
        .type-course {
          font-weight: 500;
        }
        .course-name {
          font-weight: normal;
        }
        .type-guide {
          text-decoration: underline dotted;
        }
        .course-description {
          margin-bottom: 10px;
        }
        .accordion {
          .card {
            border-radius: 0;
            border: 0;
            background-color: transparent;
            .card-header {
              background-color: transparent;
              border: 0;
              padding-left: 0;
              button {
                font-size: 20px;
                color: #31364e;
                font-weight: 500;
                line-height: 40px;
                padding-left: 55px;
                font-family: 'Work Sans';
                &:hover,
                &:focus {
                  text-decoration: none;
                }
                &[aria-expanded='true'] {
                  background: url('/img/ic-minus.svg');
                  background-repeat: no-repeat;
                  background-position: left;
                  &:hover {
                    background: url('/img/ic-minus-hover.svg');
                    background-repeat: no-repeat;
                    background-position: left;
                  }
                  &:active {
                    background: url('/img/ic-minus-focus.svg');
                    background-repeat: no-repeat;
                    background-position: left;
                  }
                }
                &[aria-expanded='false'] {
                  background: url('/img/ic-plus.svg');
                  background-repeat: no-repeat;
                  background-position: left;
                  &:hover {
                    background: url('/img/ic-plus-hover.svg');
                    background-repeat: no-repeat;
                    background-position: left;
                  }
                  &:active {
                    background: url('/img/ic-plus-focus.svg');
                    background-repeat: no-repeat;
                    background-position: left;
                  }
                }
              }
            }
            .card-body {
              padding-top: 0;
              padding-left: 0;
              ul {
                li {
                  margin-bottom: 12px;
                  padding-left: 20px;
                  position: relative;
                  font-size: 16px;
                  &:after {
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-color: #3d8be8;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
        .collection-video {
          .video-insighter-play {
            display: none;
          }
          .collection-video-details {
            padding: 20px 20px 25px 75px;
            position: relative;
            img.play,
            img.pause {
              position: absolute;
              left: 20px;
              top: 20px;
              cursor: pointer;
            }
            img.video-expand {
              position: absolute;
              right: 20px;
              top: 20px;
              cursor: pointer;
              z-index: 1;
            }
            .video-duration {
              font-size: 12px;
              font-weight: 600;
              color: #9598a6;
              position: relative;
              padding-left: 30px;
              &:after {
                content: '';
                background-image: url('/img/ic-video.svg');
                width: 24px;
                height: 24px;
                position: absolute;
                left: 0;
                top: -2px;
              }
            }
            .title {
              margin-bottom: 5px;
            }
            .description {
              font-size: 12px;
            }
          }
        }
        .title-modules {
          font-size: 28px;
          line-height: 40px;
          font-family: 'Lora';
          margin-bottom: 25px;
          margin: 100px 0 25px;
        }
        #video-insighter-list {
          text-align: left;
          .category-slide-item {
            border: 1px solid #e9e9e9;
            padding: 20px 20px 20px 100px;
            border-radius: 16px;
            margin-bottom: 20px;
            transition: all 0.3s ease-out;
            min-height: 100px;
            cursor: pointer;
            position: relative;
            &.viewed {
              background-color: rgba(61, 139, 232, 0.05);
              border: 1px solid rgba(61, 139, 232, 0.05);
              .insight-item-img {
                img.module-viewed {
                  position: absolute;
                  left: 20px;
                  top: 20px;
                  filter: grayscale(0);
                  opacity: 1;
                }
              }
            }
            &.next {
              border: 1px solid #3d8be8;
              .insight-item-img {
                img.module-play {
                  filter: grayscale(0);
                  opacity: 1;
                }
              }
            }
            &.block {
              pointer-events: none;
            }
            .insight-item-img {
              img.module-play {
                position: absolute;
                left: 20px;
                filter: grayscale(1);
                opacity: 0.2;
                transition: all 0.3s ease-out;
                width: 60px;
              }
              img.module-info {
                display: none;
              }
            }
            .insight-item-details {
              display: inline-block;
              .card-duration {
                color: #9598a6;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 10px;
              }
              .card-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
              }
              .card-text {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
              }
              .read-more {
                color: #3d8be8;
                font-size: 12px;
                text-decoration: underline dotted;
                &:hover {
                  color: #89beec;
                }
                &:active {
                  color: #2465b3;
                }
              }
            }
          }
        }
      }
      &.insight-collection3 {
        background-image: url('/img/bg-insight-collection2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 20%;
        padding: 120px 150px 60px 150px;
        margin-bottom: -60px;
        .course-name {
          font-weight: normal;
        }
        .course-description {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.what-meditation {
    padding: 60px 0 100px 150px;

    .background-image {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .container {
      text-align: left;
      margin: 0;
      padding: 0;
      position: relative;

      .type-course {
        font-family: Lora;
        font-size: 28px;
        line-height: 1.43;
        margin-bottom: 0;
      }

      .card {
        width: 555px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 1.38;

        &.guide-meditation {
          padding: 30px 25px;
          margin-top: 25px;
        }

        .card-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.1;
          margin-bottom: 0;
        }

        .card-text {
          margin-top: 19px;
        }

        .learn-more-link {
          margin-top: 19px;
        }
      }
    }
  }

  &.last-tips {
    padding: 60px 0 0;
    margin-top: 60px;
    @media screen and (max-width: 720px) {
      padding: 20px 5px 0;
      margin-top: 20px;
    }

    .background-image {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .container {
      display: grid;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      .card {
        max-width: 555px;
        align-items: center;
        margin-bottom: 60px;
        border-radius: 8px;
        width: 100% !important;

        .card-title {
          padding: 10px 0;
          font-size: 18px;
          width: 100%;
          font-weight: 600;
          line-height: 1.56;
          margin: 0;
        }

        .card-text {
          padding: 20px 46px;
          font-size: 16px;
          line-height: 1.38;
        }

        .btn {
          padding: 15px 20px;
          border-radius: 16px;
          color: $white;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 25px;
          line-height: normal;
        }

        &.blue {
          .card-title {
            padding: 10px 0;
            background: $blue-card-header;
          }

          .btn {
            background: $button-primary;

            &:hover {
              background: $button-primary-hover;
            }
            &:active {
              background: $button-primary-active;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }

        &.yellow {
          .card-title {
            padding: 10px 0;
            background: $yellow-card-header;
          }

          .btn {
            background: $button-secondary;

            &:hover {
              background: $button-secondary-hover;
            }
            &:active {
              background: $button-secondary-active;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
    }
    &.meditation {
      padding: 100px 0;
      .container {
        display: block;
        p {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.meditation {
  .swiper-button-disabled {
    display: none;
  }

  .browse-category {
    padding: 0 0px 0px 150px;
    margin-top: -30px;
    @media screen and (max-width: 1024px) {
      padding: 0 40px;
      margin-top: 0;
    }
    .title-slider {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.42;
      text-align: left;
      width: 100%;
      .popular-all {
        color: $light-grey-2;
        margin-left: 10px;
      }
    }

    .swiper-container {
      margin-top: 25px;
      @media screen and (max-width: 768px) {
        margin-right: -20px;
      }
    }

    .card {
      border-radius: 16px;

      &.image-card {
        width: 100%;
        // max-width: 263px;
        height: 330px;
        text-align: left;
        display: flex;
        justify-items: flex-end;
        justify-content: flex-end;
        padding: 20px 20px 30px;
        background-size: cover !important;
        background-position: center !important;
        color: #31364e;
        border: 0;
        text-decoration: none;
      }

      &.image-card-1 {
        background: url(/img/card-1a.png) no-repeat;
      }

      &.image-card-2 {
        background: url(/img/card-2a.png) no-repeat;
      }

      &.image-card-3 {
        background: url(/img/card-6.png) no-repeat;
      }

      &.image-card-4 {
        background: url(/img/card-4a.png) no-repeat;
      }

      &.forest {
        background: url(/img/card-10.png) no-repeat;
      }

      &.water {
        background: url(/img/card-9.png) no-repeat;
      }

      &.children {
        background: url(/img/card-8.png) no-repeat;
      }

      &.flower {
        background: url(/img/card-7.png) no-repeat;
      }
      .duration-wrapper {
        flex: 1;

        .duration {
          border-radius: 4px;
          background-color: $white-06-opacity;
          padding: 5px 10px;
          font-size: 12px;
          font-weight: 500;
          color: $text-color;
        }
        .lock {
          float: right;
        }
      }

      .card-title {
        font-family: Lora;
        font-size: 28px;
        line-height: 1.43;
      }

      .card-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
      }
    }

    .swiper-slide {
      margin-right: 30px;
      .card.image-card {
        cursor: pointer;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 15px;
          transition: all 0.3s ease-out;
          display: inline-block;
        }
        &:hover {
          &:after {
            background-color: #181a26;
            opacity: 0.35;
          }
        }
        &:focus,
        &:active,
        &:visited {
          &:after {
            background-color: #31364e;
            opacity: 0.75;
          }
        }
      }
      @media screen and (max-width: 768px) {
        margin-right: 15px !important;
      }
    }

    &.most-popular {
      margin-top: 60px;
      &-dop {
        @media screen and (max-width: 574.98px) {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      margin-top: 20px;
      @media screen and (max-width: 574.98px) {
        display: block;
      }

      .tooltip-button {
        margin-right: 15px;
        height: 50px;
        border-radius: 16px;
        border: $grey-border;
        font-size: 16px;
        font-weight: 600;
        line-height: 3;
        color: $light-grey;
        margin-right: 15px;
        min-width: 140px;
        transition: all 0.3s ease-out;
        @media screen and (max-width: 640px) {
          width: 100% !important;
          height: 44px;
          margin-bottom: 15px;
        }
        &:last-child {
          margin-right: 0px;
        }

        &:hover,
        &.tooltip-button_active {
          border: $blue-border-hover;
          @media screen and (max-width: 574.98px) {
            background-color: #ffffff;
            border-color: #2465b3;
            color: $light-grey;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          font-weight: 600;
          line-height: 2;
        }
        &_search {
          background: $button-primary;
          border: $blue-border-hover;
          color: #fff;
          height: 50px;
          border-radius: 16px;
          font-weight: 600;
          padding: 0 20px;
          &:hover {
            background: $button-primary-hover;
          }
          &:active {
            background: $button-primary-active;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
  #play {
    border: 0;
    background-color: transparent;
    &:focus,
    &:active {
      outline: none;
    }
  }
  #player {
    display: none;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    bottom: -60px;
    width: 100%;
    z-index: 0;
    padding: 0 150px;
    text-align: left;
    height: 60px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);

    &.visible {
      z-index: 2;
      display: block;
      opacity: 1;
      audio {
        width: 100%;
        height: 60px;
        display: none;
        &:focus {
          outline: none;
        }
      }
    }
    .close-player {
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
    .expand-player {
      position: absolute;
      bottom: 84px;
      right: 58px;
      cursor: pointer;
      display: none;
    }
  }
}

.sleep-collection {
  background-image:
    url('/img/bg-sleep-header.png'), url('/img/bg-sleep-footer.png');
  background-position:
    center top,
    center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  .top-column {
    padding: 100px 0 40px;
    background-image: none;
    box-shadow: none;
  }
  #play {
    border: 0;
    background-color: transparent;
    &:focus,
    &:active {
      outline: none;
    }
  }
  #player {
    display: none;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    bottom: -60px;
    width: 100%;
    z-index: 0;
    padding: 0 150px;
    text-align: left;
    height: 60px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);

    &.visible {
      z-index: 2;
      display: block;
      opacity: 1;
      audio {
        width: 100%;
        height: 60px;
        display: none;
        &:focus {
          outline: none;
        }
      }
    }
    .close-player {
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
    .expand-player {
      position: absolute;
      bottom: 84px;
      right: 58px;
      cursor: pointer;
      display: none;
    }
  }
  .type-guide {
    margin-bottom: 1rem;
  }
  .upcoming_course_card {
    .course-like {
      background-image: url('/img/ic-heart.svg');
      position: absolute;
      right: 20px;
      top: 20px;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.6);
      &.liked {
        background-image: url('/img/ic-heart-filled.svg');
      }
    }
    .course_info {
      bottom: 30px;
      top: auto;
      .course_name {
        font-weight: 800;
        font-size: 18px;
        font-family: inherit;
        line-height: 1.2;
      }
    }
  }
}
.audioplayer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  align-items: center;
  background: #fff;
}

.audioplayer-playpause {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.audioplayer-time {
  display: flex;
  width: 40px;
  justify-content: center;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
}

.audioplayer-time-current {
  margin-left: 24px;
}

.audioplayer-time-duration {
  margin-right: 24px;
}

.audioplayer-bar {
  position: relative;
  display: flex;
  margin: 0 12px;
  height: 12px;
  flex-basis: 0;
  flex-grow: 1;
  cursor: pointer;
}

.audioplayer-bar::before {
  content: '';
  position: absolute;
  top: 5px;
  width: 100%;
  height: 5px;
  background-color: #dde2e6;
}

.audioplayer-bar > div {
  position: absolute;
  left: 0;
  top: 5px;
}
.audioplayer-bar-loaded {
  z-index: 1;
  height: 5px;
  background-color: #31364e;
  opacity: 0.2;
  border-radius: 2px;
}

.audioplayer-bar-played {
  flex-direction: row-reverse;
  z-index: 2;
  height: 5px;
  background-color: #3d8be8;
  border-radius: 2px;
}

.audioplayer-bar-played::after {
  display: flex;
  position: absolute;
  content: '';
  box-sizing: border-box;
  top: -9px;
  right: -1px;
  margin-right: -5px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
}
.audioplayer-bar-played:hover::after {
  box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
  border: solid 1px #3d8be8;
}
.audioplayer-volume {
  display: flex;
  align-items: center;
}

.audioplayer-volume-button {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.audioplayer-volume-button a {
  display: flex;
  width: 6px;
  height: 8px;
  background-color: #9a9fb0;
  position: relative;
}

.audioplayer-volume-button a:before,
.audioplayer-volume-button a:after {
  content: '';
  position: absolute;
}

.audioplayer-volume-button a:before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 9px solid #9a9fb0;
  border-bottom: 8px solid transparent;
  border-left: none;
  top: -4px;
}

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
  left: 10px;
  top: -2px;
  width: 6px;
  height: 6px;
  border: 6px double #9a9fb0;
  border-width: 6px 6px 0 0;
  border-radius: 0 12px 0 0;
  transform: rotate(45deg);
}

.audioplayer-mute .audioplayer-volume-button a {
  background-color: #fd4f1a;
}

.audioplayer-mute .audioplayer-volume-button a:before {
  border-right: 9px solid #fd4f1a;
}

.audioplayer-volume-adjust {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.audioplayer-volume-adjust > div {
  position: relative;
  display: flex;
  width: 60px;
  height: 5px;
  cursor: pointer;
  background-color: #bec8d2;
}

.audioplayer-volume-adjust div div {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  background-color: #0059ff;
}
.player-song {
  margin-left: 24px;
  white-space: nowrap;
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #9598a6;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #31364e;
  }
}
.modal {
  .audioplayer {
    display: inline-block;
    flex-direction: unset;
    height: auto;
    color: #31364e;
  }
  .player-song {
    display: inline-block;
    min-width: 210px;
  }
  .audioplayer-bar {
    margin: 0 70px;
    transform: translateY(-31px);
  }
  .audioplayer-time-current {
    margin-left: 8px;
    line-height: 4;
  }
  .audioplayer-time-duration {
    display: inline-block;
    float: right;
    margin-right: 8px;
    transform: translateY(-44px);
  }
}

.video-time-hint {
  display: none;
  position: absolute;
  height: 20px;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 0 5px;
  top: -25px;
  border-radius: 4px;
  font-size: 12px;
  color: #9598a6;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    bottom: -13px;
    border: 6px solid transparent;
    border-top: 6px solid #e9e9e9;
  }
  &::after {
    border-top: 6px solid white;
    bottom: -12px;
  }
}

.video-insighter-bar:hover {
  .video-time-hint {
    display: block;
  }
}
